import React from 'react';
import PropTypes from 'prop-types';

import './Icon.scss';

const Icon = ({ icon, size='med' }) => {
	const name = Object.keys(Icon).filter((key) => Icon[key] === icon).shift().toLowerCase();
	return (
		<span className={`icon icon-${name} icon-${size}`}>
			<svg viewBox="0 0 1024 1024">
				<path d={icon}></path>
			</svg>
		</span>
	)
}

Icon.propTypes = {
	icon: PropTypes.string.isRequired,
	size: PropTypes.string
};

Icon.PLUS = 'M992 384h-352v-352c0-17.672-14.328-32-32-32h-192c-17.672 0-32 14.328-32 32v352h-352c-17.672 0-32 14.328-32 32v192c0 17.672 14.328 32 32 32h352v352c0 17.672 14.328 32 32 32h192c17.672 0 32-14.328 32-32v-352h352c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32z';
Icon.MINUS = 'M0 416v192c0 17.672 14.328 32 32 32h960c17.672 0 32-14.328 32-32v-192c0-17.672-14.328-32-32-32h-960c-17.672 0-32 14.328-32 32z';
Icon.CROSS = 'M1014.662 822.66c-0.004-0.004-0.008-0.008-0.012-0.010l-310.644-310.65 310.644-310.65c0.004-0.004 0.008-0.006 0.012-0.010 3.344-3.346 5.762-7.254 7.312-11.416 4.246-11.376 1.824-24.682-7.324-33.83l-146.746-146.746c-9.148-9.146-22.45-11.566-33.828-7.32-4.16 1.55-8.070 3.968-11.418 7.31 0 0.004-0.004 0.006-0.008 0.010l-310.648 310.652-310.648-310.65c-0.004-0.004-0.006-0.006-0.010-0.010-3.346-3.342-7.254-5.76-11.414-7.31-11.38-4.248-24.682-1.826-33.83 7.32l-146.748 146.748c-9.148 9.148-11.568 22.452-7.322 33.828 1.552 4.16 3.97 8.072 7.312 11.416 0.004 0.002 0.006 0.006 0.010 0.010l310.65 310.648-310.65 310.652c-0.002 0.004-0.006 0.006-0.008 0.010-3.342 3.346-5.76 7.254-7.314 11.414-4.248 11.376-1.826 24.682 7.322 33.83l146.748 146.746c9.15 9.148 22.452 11.568 33.83 7.322 4.16-1.552 8.070-3.97 11.416-7.312 0.002-0.004 0.006-0.006 0.010-0.010l310.648-310.65 310.648 310.65c0.004 0.002 0.008 0.006 0.012 0.008 3.348 3.344 7.254 5.762 11.414 7.314 11.378 4.246 24.684 1.826 33.828-7.322l146.746-146.748c9.148-9.148 11.57-22.454 7.324-33.83-1.552-4.16-3.97-8.068-7.314-11.414z';
Icon.CHECK = 'M864 128l-480 480-224-224-160 160 384 384 640-640z';
Icon.ADDRESSBOOK = 'M192 0v1024h768v-1024h-768zM576 256.33c70.51 0 127.67 57.16 127.67 127.67s-57.16 127.67-127.67 127.67-127.67-57.16-127.67-127.67 57.16-127.67 127.67-127.67v0zM768 768h-384v-64c0-70.696 57.306-128 128-128v0h128c70.696 0 128 57.304 128 128v64z M64 64h96v192h-96v-192z M64 320h96v192h-96v-192z M64 576h96v192h-96v-192z M64 832h96v192h-96v-192z';
Icon.MAIL = 'M928 128h-832c-52.8 0-96 43.2-96 96v640c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-640c0-52.8-43.2-96-96-96zM398.74 550.372l-270.74 210.892v-501.642l270.74 290.75zM176.38 256h671.24l-335.62 252-335.62-252zM409.288 561.698l102.712 110.302 102.71-110.302 210.554 270.302h-626.528l210.552-270.302zM625.26 550.372l270.74-290.75v501.642l-270.74-210.892z';
Icon.GMAIL = 'M1024 192v640c0 36.267-27.733 64-64 64h-64v-580.821l-384 275.755-384-275.755v580.821h-64c-36.309 0-64-27.733-64-64v-640c0-18.133 6.912-34.133 18.389-45.568 11.477-11.605 27.52-18.432 45.611-18.432h21.333l426.667 309.333 426.667-309.333h21.333c18.133 0 34.133 6.912 45.611 18.432 11.52 11.435 18.389 27.435 18.389 45.568z';
Icon.YAHOO = 'M513.2 69.6c-181 0-352-23.8-513.2-69.6 0 361.8 0 933.2 0 1024 161.4-45.8 332.4-69.6 513.2-69.6 178.8 0 349.4 23.2 510.8 69.6 0-348.4 0-649.8 0-1024-161.4 46.4-331.8 69.6-510.8 69.6zM796.8 157l-6.2 9.8c-5.8 9.2-11 17-18.2 28-9.6 14.4-27.6 43-49.2 79.8-6 10.2-13.4 22.4-21 35.6-14.6 24.6-31 52.4-44 74.4-5.4 9.4-10.8 19-16.4 28.6-14.4 25-29.2 50.8-43.4 75.6-14.6 25.8-29 51.2-43.4 76.4v25.4c0 35.2 0.8 73.6 2 107.8 0.6 15.6 1.2 43.4 2 72.8 0.8 35 1.6 71.2 2.6 89.6l0.2 5.6v0.6l-6-1.6c-2.4-0.6-4.6-1.2-7-1.8-7.2-1.6-15-2.8-22.6-3.6-4.6-0.4-9.4-0.6-14.2-0.6 0 0 0 0 0 0s0 0 0 0c-4.8 0-9.6 0.2-14.2 0.6-7.6 0.8-15.4 2-22.6 3.6-2.4 0.6-4.8 1.2-7 1.8l-6 1.6v-0.6l0.2-5.6c0.8-18.2 1.8-54.6 2.6-89.6 0.6-29.4 1.4-57.2 2-72.8 1.4-34.4 2-72.6 2-107.8v-25.4c-14.4-25.4-28.8-50.6-43.4-76.4-14.2-25-29-50.6-43.2-75.6-5.6-9.6-11-19.2-16.4-28.6-12.8-22.2-29.4-50-44-74.4-7.8-13-15.2-25.4-21-35.6-21.6-36.8-39.6-65.2-49.2-79.8-7.2-11-12.4-18.8-18.2-28l-6.2-9.8 11.2 3.2c14.2 4 28.8 6 44.4 6s30.6-2 44.6-6l3.4-1 1.8 3c27.6 49.8 101.8 171.8 146.2 244.8 15.2 25.2 27.4 45 33.4 55.2 0 0 0 0 0-0.2 0 0 0 0 0 0.2 6-10 18.2-30 33.4-55.2 44.4-72.8 118.6-194.8 146.2-244.8l1.8-3 3.4 1c14 4 29 6 44.6 6s30.2-2 44.4-6l10.6-3.2z';
Icon.OUTLOOK = 'M1024 315.179v447.061c0 9.813-3.413 18.091-10.155 24.576-6.741 6.571-15.019 9.813-24.747 9.813h-364.672v-296.917l68.267 52.437c4.352 3.627 9.771 5.376 16.171 5.376 6.315 0 11.819-1.749 16.597-5.419l298.539-236.928zM624.427 228.949h364.672c9.003 0 16.768 2.688 23.168 8.192 6.4 5.461 9.984 12.8 10.581 21.76l-314.411 250.709-84.011-66.091v-214.571zM571.904 36.864v950.229l-571.904-98.816v-749.867l571.904-101.547zM399.147 513.877c-0.853-48.341-13.355-88.405-37.504-120.064-23.68-31.573-54.4-48.256-90.923-49.664-35.157 1.408-65.237 18.048-89.6 49.664-24.32 31.659-36.48 71.765-37.12 120.064 0.64 47.659 13.44 87.339 37.76 119.083 24.363 31.573 54.357 48.341 89.643 50.176 36.48-1.493 67.157-18.091 91.52-49.92 24.32-31.915 37.12-71.68 37.76-119.339h-1.536zM266.027 407.979c18.389 0.853 33.877 10.923 46.208 30.592 12.16 19.669 18.56 44.587 18.56 74.752 0 30.763-6.357 55.765-18.56 75.563-12.843 19.797-28.16 30.037-46.763 30.037s-33.92-9.643-46.72-29.44-18.56-44.8-18.56-74.837c0-30.080 5.76-55.083 18.56-74.24 12.117-19.2 27.563-29.44 46.123-30.763l1.152-1.664z';
Icon.PHONE = 'M736 0h-448c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h448c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 48h256v32h-256v-32zM512 960c-35.346 0-64-28.654-64-64s28.654-64 64-64 64 28.654 64 64-28.654 64-64 64zM768 768h-512v-640h512v640z';
Icon.LOCATION = 'M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z';
Icon.GITHUB = 'M512.008 12.642c-282.738 0-512.008 229.218-512.008 511.998 0 226.214 146.704 418.132 350.136 485.836 25.586 4.738 34.992-11.11 34.992-24.632 0-12.204-0.48-52.542-0.696-95.324-142.448 30.976-172.504-60.41-172.504-60.41-23.282-59.176-56.848-74.916-56.848-74.916-46.452-31.778 3.51-31.124 3.51-31.124 51.4 3.61 78.476 52.766 78.476 52.766 45.672 78.27 119.776 55.64 149.004 42.558 4.588-33.086 17.852-55.68 32.506-68.464-113.73-12.942-233.276-56.85-233.276-253.032 0-55.898 20.004-101.574 52.76-137.428-5.316-12.9-22.854-64.972 4.952-135.5 0 0 43.006-13.752 140.84 52.49 40.836-11.348 84.636-17.036 128.154-17.234 43.502 0.198 87.336 5.886 128.256 17.234 97.734-66.244 140.656-52.49 140.656-52.49 27.872 70.528 10.35 122.6 5.036 135.5 32.82 35.856 52.694 81.532 52.694 137.428 0 196.654-119.778 239.95-233.79 252.624 18.364 15.89 34.724 47.046 34.724 94.812 0 68.508-0.596 123.644-0.596 140.508 0 13.628 9.222 29.594 35.172 24.566 203.322-67.776 349.842-259.626 349.842-485.768 0-282.78-229.234-511.998-511.992-511.998z';
Icon.LINKEDIN = 'M928 0h-832c-52.8 0-96 43.2-96 96v832c0 52.8 43.2 96 96 96h832c52.8 0 96-43.2 96-96v-832c0-52.8-43.2-96-96-96zM384 832h-128v-448h128v448zM320 320c-35.4 0-64-28.6-64-64s28.6-64 64-64c35.4 0 64 28.6 64 64s-28.6 64-64 64zM832 832h-128v-256c0-35.4-28.6-64-64-64s-64 28.6-64 64v256h-128v-448h128v79.4c26.4-36.2 66.8-79.4 112-79.4 79.6 0 144 71.6 144 160v288z';
Icon.SPINNER = 'M512 1024c-136.76 0-265.334-53.258-362.040-149.96-96.702-96.706-149.96-225.28-149.96-362.040 0-96.838 27.182-191.134 78.606-272.692 50-79.296 120.664-143.372 204.356-185.3l43 85.832c-68.038 34.084-125.492 86.186-166.15 150.67-41.746 66.208-63.812 142.798-63.812 221.49 0 229.382 186.618 416 416 416s416-186.618 416-416c0-78.692-22.066-155.282-63.81-221.49-40.66-64.484-98.114-116.584-166.15-150.67l43-85.832c83.692 41.928 154.358 106.004 204.356 185.3 51.422 81.558 78.604 175.854 78.604 272.692 0 136.76-53.258 265.334-149.96 362.040-96.706 96.702-225.28 149.96-362.040 149.96z';

export default Icon;
